<template>
  <view-container breadcrumbs class="white py-0 px-0" fluid>
    <v-card flat>
      <toolbar :title="$t('srs.newsFeed')">
        <v-text-field
          class="d-none d-md-flex"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('general.search')"
          single-line
          hide-details
          dense
          clearable
          style="width: 0"
        ></v-text-field>

        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-btn :loading="!enableRefresh" :disabled="!enableRefresh" text x-small color="green" @click="refreshAction">
          <v-icon>mdi-web-refresh</v-icon>
          <span class="text--secondary">{{ $t("general.refresh") }}</span>
        </v-btn>
        <v-divider vertical inset class="d-none d-md-flex mx-2" />
        <v-btn :disabled="!this.selected" text x-small color="green" @click="newAction">
          <v-icon>mdi-invoice-text-plus-outline</v-icon>
          <span class="text--secondary">{{ $t("general.new") }}</span>
        </v-btn>
      </toolbar>

      <!-- <feeds-filter
        v-model="filter"
        @clear="clearFilter"
        @filter="loadItems"
        v-show="toggleFilter"
      /> -->

      <v-card-text class="overflow-y-auto px-0 py-0" :style="viewHeight(122)">
        <v-data-table
          dense
          :headers="getHeaders"
          :items="getItems"
          item-key="id"
          show-select
          single-select
          :value="selection"
          :loading="loading"
          :loading-text="$t('messages.loading')"
          @item-selected="selectionChanged"
        >
          <template #item.message="{ item }">
            <div>{{ item.message }}</div>
            <div class="my-1">
              <v-chip label small color="primary">{{ item.date }}</v-chip>
              <v-chip class="mx-1" small color="primary">{{ item.platform }}</v-chip>
              <v-chip small color="secondary">{{ item.channel }}</v-chip>
            </div>
          </template>

          <template #item.data-table-select="{ item, isSelected, select }">
            <div class="d-flex">
              <v-simple-checkbox
                on-icon="mdi-checkbox-marked-circle"
                off-icon="mdi-power-off"
                :value="isSelected"
                light
                @input="select($event)"
                :ripple="false"
                class="mx-0"
              ></v-simple-checkbox>
            </div>
          </template>

          <template #item.details="{ item }">
            <v-card class="ma-1 d-flex">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>{{ $t("si.incidentType") }}</td>
                      <td>
                        <v-chip-group
                          v-model="item.incidentType"
                          active-class="primary--text"
                          column
                        >
                          <v-chip small v-for="cat in incidentTypes" :key="cat" class="ma-1">
                            {{ cat }}
                          </v-chip>
                        </v-chip-group>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("si.matchDegree") }}</td>
                      <td>
                        <v-progress-circular
                          size="35"
                          width="3"
                          color="primary"
                          :value="item.similarity * 100"
                        >
                          <span style="font-size: 10pt">
                            {{
                              item.similarity.toLocaleString("en", {
                                style: "percent",
                                minimumFractionDigits: 0,
                              })
                            }}
                          </span>
                        </v-progress-circular>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t("si.locations") }}</td>
                      <td>
                        <v-chip-group
                          v-model="item.location"
                          active-class="primary--text"
                          column
                        >
                          <v-chip
                            small
                            label
                            v-for="loc in item.locations || []"
                            :key="loc"
                            class="ma-1"
                          >
                            {{ loc }}
                          </v-chip>
                        </v-chip-group>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </template>
          <!-- <template #item.category="{ item }">
            <v-chip small label color="primary">
              {{ item.category }}
            </v-chip>
          </template> -->

          <!-- <template #item.similarity="{ item }">
            <v-progress-circular
              size="35"
              width="3"
              color="primary"
              :value="item.similarity * 100"
            >
              <span style="font-size: 10pt;">
                {{
                  item.similarity.toLocaleString("en", {
                    style: "percent",
                    minimumFractionDigits: 0,
                  })
                }}
              </span>
            </v-progress-circular>
          </template> -->
        </v-data-table>

        <!-- <div
          class="text-center px-2 d-flex justify-space-between pt-2"
          style="border-top: 1px dashed #ccc"
        >
          <div class="ma-3">{{ getTotal }} Reports</div>
          <v-select
            :value="pageSize"
            :items="[100, 500, 1000]"
            label="Reports per page"
            @input="pageSize = parseInt($event)"
            style="font-size: 10pt; max-width: 200px"
            solo
            dense
          ></v-select>
        </div> -->
      </v-card-text>
    </v-card>
  </view-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ViewContainer from "@/common/layouts/ViewContainer";
import Toolbar from "@/common/components/Toolbar";
// import FeedsFilter from "./FeedsFilter";

export default {
  name: "Feeds",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    ViewContainer,
    Toolbar,
    // FeedsFilter,
  },
  data() {
    return {
      x: [],
      enableRefresh: true,
      page: 1,
      pageSize: 100,
      search: "",
      loading: false,
      expanded: [],
      toggleFilter: true,
      filter: {
        channel: { name: "@pravdaGerashchenko_en", lang: "en", source: "Telegram" },
        nom: 25,
        min_similarity: "60%",
      },
      filterReset: {
        channel: { name: "@pravdaGerashchenko_en", lang: "en", source: "Telegram" },
        nom: 25,
        min_similarity: "60%",
      },
      items: [],
      incidentTypes: [
        "Armed Conflict",
        "Civil Unrest",
        "Crime",
        "Hazard",
        "Inhabiting Context",
        "Terrorist Attack",
      ],
    };
  },
  mounted() {
    this.loading = true;
    this.loadItems();
  },
  computed: {
    ...mapGetters("feeds", ["getFeeds", "getFeedsTotal", "getSelectedFeed"]),

    getItems() {
      return this.search
        ? this.items.filter(
            (f) =>
              (f.message + f.category + (f.locations || []).join(""))
                .toLowerCase()
                .indexOf(this.search.toLowerCase()
              ) !== -1
          )
        : this.items;
    },

    getHeaders() {
      return [
        { text: this.$t("si.feed"), value: "message", sortable: false },
        { text: this.$t("srs.details"), value: "details", sortable: false },
        // { text: this.$t("srs.dateTime"), value: "date", width: 70, sortable: false },
        // { text: this.$t("si.locations"), value: "locations", width: 70, sortable: false },
        // { text: this.$t("si.incidentType"), value: "category", sortable: false },
        // { text: this.$t("si.matchDegree"), value: "similarity", sortable: false },
      ];
    },

    selected() {
      return this.getSelectedFeed;
    },
    selection() {
      return !this.getSelectedFeed ? [] : [this.getSelectedFeed];
    },
    getTotal() {
      const total = this.getFeedsTotal;
      return total ? total.toLocaleString("en") : 0;
    },
  },
  methods: {
    ...mapActions("feeds", [
      "loadFeeds",
      "syncAndLoadFeeds",
      "setSelectedFeed",
      "clearSelectedFeed",
    ]),
    ...mapActions("ui", ["showDialog", "closeDialog"]),

    loadItems() {
      this.loading = true;
      this.loadFeeds({
        channel: this.filter.channel.name,
        lang: this.filter.channel.lang,
        nom: this.filter.nom,
        min_similarity: parseInt(this.filter.min_similarity) / 100,
      })
        .then((items) => {
          this.items = (items || []).map((item) => {
            item.incidentType = this.incidentTypes.indexOf(item.category);
            return item;
          });
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    selectionChanged(e) {
      if (e.value) {
        this.setSelectedFeed(e.item);
      } else {
        this.clearSelectedFeed();
      }
    },
    newAction() {
      const item = this.items.find((f) => f.id === this.selected.id);
      console.log(item);
      this.$router.push({
        name: "incident",
        params: { incidentId: "new", item },
      });
    },
    refreshAction() {
      this.enableRefresh = false;
      this.syncAndLoadFeeds().then(() => {
        this.clearSelectedFeed();
        this.enableRefresh = true;
      }).catch(() => {
        this.enableRefresh = true;
      });
    },

    clearFilter() {
      this.filter = { ...this.filterReset };
    },

    cutText(text) {
      return text && text.length > 100 ? text.substr(0, 100) : text;
    },
  },
};
</script>
